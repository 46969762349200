<ng-container *ngIf="league != null">
  <app-league-styles></app-league-styles>
  <div class="playoffs-container" [ngClass]="league.superbowlYear % 2 == 0 ? 'afc-home' : 'nfc-home'">
    <div class="row conference-names">
      <h2>{{ league.leagueName }} Playoff Picture</h2>
    </div>
    <div class="row">
      <ng-container *ngIf="playoffGames['sb'].teamSelected">
        <mat-card [ngClass]="playoffGames['sb'].teamSelected.team.teamAbbreviation">
          <button title="{{ playoffGames['sb'].teamSelected.team.regionName }} {{ playoffGames['sb'].teamSelected.team.teamName }}"
            mat-raised-button color="secondary" [ngClass]="playoffGames['sb'].teamSelected.team.teamAbbreviation" class="sb">
            <img
              title="{{ playoffGames['sb'].teamSelected.team.regionName }}
            {{ playoffGames['sb'].teamSelected.team.teamName }}"
              src="https://static.playoffpredictors.com/nfl/{{ playoffGames['sb'].teamSelected.team.teamLogo || playoffGames['sb'].teamSelected.team.teamAbbreviation }}.svg"
              title="playoffGames['sb'].teamSelected.team.teamName"
            />
            <p>
              {{ playoffGames['sb'].teamSelected.team.teamName }} <sup>{{ playoffGames['sb'].teamSelected.seed }}</sup>
            </p>
          </button>
        </mat-card>
      </ng-container>
      <ng-container *ngIf="!playoffGames['sb'].teamSelected"> <button mat-raised-button color="secondary">&nbsp;</button> </ng-container>
    </div>
    <div class="row"><p>Super Bowl</p></div>
    <div class="row">
      <img fxHide.lt-lg src="https://static.playoffpredictors.com/nfl/AFC.svg" width="75" class="afc" />
      <app-playoff-game [playoffGame]="playoffGames['sb']" class="wide sb"
        [homePlaceholderText]="league.superbowlYear % 2 == 0 ? 'AFC' : 'NFC'"
        [awayPlaceholderText]="league.superbowlYear % 2 == 0 ? 'NFC' : 'AFC'"
        [enableScoring]="enableScoring"
        [awayScore]="sbAwayScore"
        [homeScore]="sbHomeScore"
        (scoreUpdated)="sbScoreUpdated($event)"
        ></app-playoff-game>
      <img fxHide.lt-lg src="https://static.playoffpredictors.com/nfl/NFC.svg" width="75" class="nfc" />
    </div>
  <div class="row" fxHide fxShow.lt-lg>
    <div><span> Conference Championship</span></div>
  </div>
    <div class="row">
      <div class="flex"><app-playoff-game [playoffGame]="playoffGames['afc-cg']" class="wide"  [homePlaceholderText]="'HR'" [awayPlaceholderText]="'LR'"></app-playoff-game></div>
      <div class="column" fxHide fxShow.gt-md><span class='text-center'> Conference Championship</span></div>
      <mat-divider fxHide fxShow.gt-sm fxHide.gt-md [inset]="true"></mat-divider>
      <div class="flex"><app-playoff-game [playoffGame]="playoffGames['nfc-cg']" class="wide"  [homePlaceholderText]="'HR'" [awayPlaceholderText]="'LR'"></app-playoff-game></div>
    </div>
    <div class="row" fxHide fxShow.lt-lg>
      <div><span> Divisional Round</span></div>
    </div>
    <div class="row">
      <div class="flex">
        <app-playoff-game [playoffGame]="playoffGames['afc-dv-2']" class="wide" [homePlaceholderText]="'HR'" [awayPlaceholderText]="'SLR'"></app-playoff-game>
        <app-playoff-game [playoffGame]="playoffGames['afc-dv-1']" class="wide" [awayPlaceholderText]="'LR'"></app-playoff-game>
      </div>
      <div class="column" fxHide fxShow.gt-md><span class='text-center'> Divisional Round </span></div>
      <mat-divider fxHide fxShow.gt-sm fxHide.gt-md [inset]="true"></mat-divider>
      <div class="flex">
        <app-playoff-game [playoffGame]="playoffGames['nfc-dv-1']" class="wide" [awayPlaceholderText]="'LR'"></app-playoff-game>
        <app-playoff-game [playoffGame]="playoffGames['nfc-dv-2']" class="wide" [homePlaceholderText]="'HR'" [awayPlaceholderText]="'SLR'"></app-playoff-game>
      </div>
    </div>

    <div class="row" fxHide fxShow.lt-lg>
      <div><span> Wild Card</span></div>
    </div>
    <div class="row">
      <div class="flex">
        <app-playoff-game [playoffGame]="playoffGames['afc-wc-2']" class="stacked"></app-playoff-game>
        <app-playoff-game [playoffGame]="playoffGames['afc-wc-1']" class="stacked"></app-playoff-game>
        <app-playoff-game [playoffGame]="playoffGames['afc-wc-3']" class="stacked"></app-playoff-game>
      </div>
      <div class="column" fxHide fxShow.gt-md><span class='text-center'>Wild Card</span></div>
      <mat-divider fxHide fxShow.gt-sm fxHide.gt-md [inset]="true"></mat-divider>
      <div class="flex">
        <app-playoff-game [playoffGame]="playoffGames['nfc-wc-2']" class="stacked"></app-playoff-game>
        <app-playoff-game [playoffGame]="playoffGames['nfc-wc-1']" class="stacked"></app-playoff-game>
        <app-playoff-game [playoffGame]="playoffGames['nfc-wc-3']" class="stacked"></app-playoff-game>
      </div>
    </div>
    <div class="row button-row">
      <button mat-raised-button (click)="toggleScoring()">Toggle SB Scores</button>
      <button mat-raised-button color="warn" (click)="clear()">Clear</button>
      <button mat-raised-button color="primary" (click)="refresh()">Refresh</button>
      <app-tweet [url]="shareUrl" [text]="'Check out my #NFLPlayoffs Picks that I made on #PlayoffPredictors'"></app-tweet>
    </div>
    <div class="row">
      <p>Share Url: {{ shareUrl }} <button mat-button (click)="copyToClipboard()" #copyTooltip="matTooltip" [matTooltipDisabled]="true" [matTooltip]="'copied'"><mat-icon>content_copy</mat-icon></button> </p>
      <p>Playoff Predictors survives by people like you sharing our website. Please include a link to Playoff Predictors when you share your bracket.</p>
    </div>
    <div class="row">
      <dl>
        <dt>HR</dt>
        <dd>Highest Remaining Seed</dd>
        <dt>LR</dt>
        <dd>Lowest Remaining Seed</dd>
        <dt>SLR</dt>
        <dd>Second-Lowest Remaining Seed</dd>
      </dl>
    </div>
    <app-bracket></app-bracket>
  </div>
</ng-container>
